<template>
  <div class="announce">
    <div class="announce-text">
      <div>Turdor Caravan Park's</div>
      <div>Information</div>
    </div>
    <div class="announce-info card">
      <p class="title">Important Information</p>
      <p>
        Tudor Park Equity Pty Ltd (Tudor Equity Fund) is offering an opportunity
        for a few investors to own Preferred Shares in Tudor Park Equity Pty Ltd
        (Investment Opportunity).
      </p>
      <p>
        The purpose of this Information Memorandum (IM) is to provide potential
        investors with general information about the structure of the Tudor
        Equity Fund and an overview of what an investor can expect as an owner
        of Preferred Shares in the Tudor Equity Fund.
      </p>
      <p>
        The Investment Opportunity is a small-scale personal offering and is
        therefore not required to be disclosed. This IM is not a disclosure
        document and should not be relied upon in determining whether to invest
        and purchase Preferred Shares in the Tudor Equity Fund.
      </p>
      <p>The Tudor Equity Fund encourages all potential investors to:</p>
      <p>
        1. Review the Legal Documents relating to the Tudor Equity Fund which
        will be made available to you upon request; and
      </p>
      <p>
        2. Obtain independent legal and financial advice about the Legal
        Documents and as to the suitability of the purchase of Preferred Shares
        in the Tudor Equity Fund.
      </p>
      <p>By accepting a copy of this IM, you acknowledge and agree:</p>
      <ul>
        <li>
          That this IM is provided on a strictly private and confidential basis
          and is to only be used by the person by which ACPI Pty Ltd has
          provided it to;
        </li>
        <li>
          That this IM has been prepared solely to provide background
          information in relation to the Investment Opportunity and it is not to
          be used for any other purpose;
        </li>
        <li>
          Not to copy, forward or replicate the content of this IM without the
          prior written consent of ACPI Pty Ltd;
        </li>
        <li>
          That except to the extent implied by law, no representation or
          warranty as to the validity, certainty or completeness of any of the
          assumptions or the accuracy of the information, opinions, estimates or
          forecasts contained in this IM is made by ACPI Pty Ltd or any of its
          affiliated entities, officers, employers, agents or advisers; and
        </li>
        <li>
          That this IM is not to be considered as a recommendation that you
          should invest in the Tudor Equity Fund.
        </li>
      </ul>
    </div>

    <div class="announce-grid card">
      <div class="title">Recent Success - Pakenham Project</div>
      <div class="date">Park Purchased 21/04/2020</div>
      <div class="grid">
        <div class="row header">
          <div class="item"></div>
          <div class="item">ORIGINAL</div>
          <div class="item">AFTER</div>
        </div>
        <div class="row">
          <div class="item">Purchase Price</div>
          <div class="item">$7,500,000</div>
          <div class="item">$7,500,000</div>
        </div>
        <div class="row">
          <div class="item">Construction Cost</div>
          <div class="item"></div>
          <div class="item">$600,000</div>
        </div>
        <div class="row">
          <div class="item">Operating Profit</div>
          <div class="item">$600,000</div>
          <div class="item">$800,000</div>
        </div>
        <div class="row">
          <div class="item">Yield(p.a.)</div>
          <div class="item">8%</div>
          <div class="item">10%</div>
        </div>
        <div class="row">
          <div class="item"><h3>Improved Net Profix</h3></div>
          <div class="item"></div>
          <div class="item"><h3>$200,000</h3></div>
        </div>
        <div class="row">
          <div class="item"><h3>Value Capture(Capex Gain) at 8%</h3></div>
          <div class="item"></div>
          <div class="item"><h3>&2,500,000</h3></div>
        </div>
        <div class="row">
          <div class="item"><h3>Total expected time taken</h3></div>
          <div class="item"></div>
          <div class="item"><h3>15 Months</h3></div>
        </div>
      </div>

      <!-- <div class="summary">
        <div class="row">
          <div class="item">Improved Net Profix</div>
          <div class="item"></div>
          <div class="item">$200,000</div>
        </div>
        <div class="row summary">
          <div class="item">Value Capture(Capex Gain) at 8%</div>
          <div class="item"></div>
          <div class="item">&2,500,000</div>
        </div>
        <div class="row summary">
          <div class="item">Total expected time taken</div>
          <div class="item"></div>
          <div class="item">15 Months</div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "Announce",
};
</script>
<style lang="scss">
.announce {
  background-image: url("/images/carbin.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .announce-text {
    width: var(--panel-width);
    color: white;
    font-size: 40px;
    margin: 100px 0;
  }

  .card {
    width: var(--panel-width);
    background-color: white;
    border: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;
    padding: 70px;
  }

  .announce-info {
    p,
    li {
      padding: 10px;
    }

    .title {
      margin: 0 auto;
      font-size: 36px;
      color: var(--primary-color);
      padding-bottom: 50px;
    }
  }

  .announce-grid {
    margin: 50px 0;

    .title {
      font-size: 36px;
      color: var(--primary-color);
      text-align: center;
      padding-bottom: 30px;
    }

    .date {
      // font-family: "Din";
      font-size: 20px;
      color: var(--primary-color);
      padding-bottom: 10px;
    }
    .header {
      color: white;
      background-color: var(--primary-color);
      font-style: italic;
    }

    .row:nth-child(even) {
      background-color: #eeeeee;
    }

    .grid {
      .row {
        display: flex;
        border-bottom: 1px solid silver;
        .item {
          flex: 0 0 33%;
          padding: 10px;
        }
        .item:nth-child(1) {
          // text-align: center;
        }
        .item:nth-child(2) {
          text-align: center;
        }
        .item:nth-child(3) {
          text-align: right;
        }
      }
    }

    .summary {
      .row {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid silver;

        .item {
          color: var(--primary-color);
          // font-size: 14px;
          font-weight: bold;
          padding: 10px;
        }
      }
    }
  }
}
</style>